import styles from '../../styles/Footer.module.scss'
import PageContainer from "../layouts/PageContainer";
import {Box, Typography} from "@mui/material";
import Link from "next/link";
import SocialNetworks from "../shared/SocialNetworks";
import companyInfo from "../../constants/address.json";


export default function Footer() {
    return (<footer className={styles.footerContainer}>
        <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <PageContainer>
                    <Box className={styles.footerInnerContainer}>
                        <Box className={styles.footerInnerTop}>
                            {/*<Box className={styles.footerItem}>*/}
                            {/*    <Typography variant={"h6"} component={"h6"} fontSize={30}*/}
                            {/*                fontWeight={"bold"}>Address</Typography>*/}
                            {/*    <Typography variant={"body1"} fontSize={20}>{companyInfo["company"]}</Typography>*/}
                            {/*    <Typography variant={"body1"} fontSize={20}>{companyInfo["city"]}</Typography>*/}
                            {/*    <Typography variant={"body1"} fontSize={20}>{companyInfo["street"]}</Typography>*/}
                            {/*</Box>*/}
                            <Box className={styles.footerItem}>
                                <Typography variant={"h6"} component={"h6"} fontSize={30}
                                            fontWeight={"bold"}>Contact</Typography>
                                <Typography variant={"body1"} fontSize={20}><Link href={"tel:" + companyInfo["phone"]}>{companyInfo["phone"]}</Link></Typography>
                                <Typography variant={"body1"} fontSize={20}><Link target={"_blank"} href={"mailto:" + companyInfo["email"]}>{companyInfo["email"]}</Link></Typography>
                            </Box>
                            <Box className={styles.footerItem}>
                                <Typography variant={"h6"} component={"h6"} fontSize={30} fontWeight={"bold"}>
                                    Links
                                </Typography>
                                <Link href={"/page/about-us"} style={{fontSize: 20}}>About Us</Link>
                                <Link href={"/press-releases"} style={{fontSize: 20}}>Press Releases</Link>
                                <Link href={"/page/terms-of-service"} style={{fontSize: 20}}>Terms of Service</Link>
                                <Link href={"/page/privacy-policy"} style={{fontSize: 20}}>Privacy Policy</Link>
                            </Box>
                            <Box className={styles.socials}>
                                {/*<SocialNetworks/>*/}
                            </Box>
                        </Box>
                    </Box>
                </PageContainer>
            </Box>
            <Box className={styles.footerCopyRights}>
                {new Date().getFullYear()} © All rights reserved. Powered by XOP Networks
            </Box>
        </Box>
    </footer>)
}
