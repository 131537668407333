import socialNetworks from '../../constants/social_networks.json';
import {IconButton} from "@mui/material";
import Link from "next/link";
import Box from "@mui/material/Box";
import {Facebook, Instagram, LinkedIn, Twitter, YouTube} from "@mui/icons-material";

export default function SocialNetworks() {
    return (
        <Box>
            {Object.keys(socialNetworks).map((key, i) => {
                switch (key) {
                    case 'facebook':
                        return <IconButton key={'sn' + i} component={Link} href={socialNetworks[key]} title={"Facebook account"} target={"_blank"}><Facebook/></IconButton>
                    case 'linkedin':
                        return <IconButton key={'sn' + i} component={Link} href={socialNetworks[key]} title={"LinkedIn account"}  target={"_blank"}><LinkedIn/></IconButton>
                    case 'twitter':
                        return <IconButton key={'sn' + i} component={Link} href={socialNetworks[key]} title={"Twitter account"}  target={"_blank"}><Twitter/></IconButton>
                    case 'youtube':
                        return <IconButton key={'sn' + i} component={Link} href={socialNetworks[key]} title={"YouTube account"}  target={"_blank"}><YouTube/></IconButton>
                    case 'instagram':
                        return <IconButton key={'sn' + i} component={Link} href={socialNetworks[key]} title={"Instagram account"}  target={"_blank"}><Instagram/></IconButton>
                    default:
                        return null
                }
            })}
        </Box>
    )
}
