import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CacheProvider} from '@emotion/react';
import {CssBaseline, ThemeProvider} from '@mui/material';

import createEmotionCache from '../utility/createEmotionCache';
import lightTheme from '../styles/theme/lightTheme';
import '../styles/globals.css';
import '../components/layouts/MainLayout'
import MainLayout from "../components/layouts/MainLayout";
import {useRouter} from "next/router";
import enUS from '../lang/en-US.json';
import lvLV from '../lang/lv-LV.json';
import {IntlProvider} from "react-intl";
import NextNProgress from 'nextjs-progressbar';
import CookieConsent from "../components/shared/CookieConsent";
import BackToTopButton from "../components/shared/BackToTopButton";
import LoadingIndicator from "../components/shared/LoadingIndicator";

1

const clientSideEmotionCache = createEmotionCache();

const messages = {
    'en-US': enUS,
    'lvLV': lvLV
};

const MyApp = (props) => {
    const {locale} = useRouter();
    const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500); // Adjust the delay as needed

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <CacheProvider value={emotionCache}>
            <ThemeProvider theme={lightTheme}>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                        <LoadingIndicator visible={isLoading ? true : false}/>
                        <MainLayout>
                            <NextNProgress options={{showSpinner: false}}/>
                            <CssBaseline/>
                            <CookieConsent/>
                            <Component {...pageProps} />
                            <BackToTopButton />
                        </MainLayout>
                    </IntlProvider>
            </ThemeProvider>
        </CacheProvider>
    );
};

export default MyApp;

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    emotionCache: PropTypes.object,
    pageProps: PropTypes.object.isRequired,
};
