import {Box, Typography} from "@mui/material";
import styles from '../../styles/Logo.module.css'
import Link from "next/link";

export default function Logo() {
    return (
        <Box display={"flex"} flexDirection={"row"} alignSelf={"center"}>
            <Link href={'/'}>
                <img src='/images/logo.svg' alt='InstaConference Logo' className={styles.logo}/>
            </Link>
        </Box>
    )
}
