import {Box, Typography} from "@mui/material";
import styles from '../../styles/SideElements.module.css'
import clsx from "clsx";

export default function StyledSideImage({side = 'left'}) {

    return (
        <Box id={side + "SideElement"} className={clsx(styles.sideElement, (side === 'left') ? styles.sideElementLeft : styles.sideElementRight)} />
    )
}
